import { GlobeAltIcon, MapPinIcon } from "@heroicons/react/24/outline"
import { Link } from "gatsby"
import React, { Component } from "react"
import Button from "../components/base/Button"
import Footer from "../components/base/Footer"
import Header from "../components/base/Header"
import Overlay from "../components/base/Overlay"
import SEO from "../components/base/seo"
import Hero from "../components/marketing/Hero"
import LeftContentCard from "../components/marketing/LeftContentCard"
import RightContentCard from "../components/marketing/RightContentCard"
import SubscribeAction from "../components/marketing/SubscribeAction"
import Whatsapp from "../components/marketing/Whatsapp"
import AboutMPOB from "../images/about_mbop.webp"
import CarbonISCC from "../images/carbon_emission.png"
import MockUp from "../images/home_mockup.png"
import JMBTransaction from "../images/jmb_transaction.png"
import { Language } from "../store/reducers/utilsReducer"
import { localeContent } from "../utils/locale"

interface Props {
  language: Language
}

export default class CarbonEmission extends Component<Props> {
  state = {
    visible: false,
    loading: false,
  }

  renderMethodology() {
    const language = Language.EN
    return (
      <>
        <p className="mt-4 text-base leading-7 text-gray-500 whitespace-pre-line">
          {
            localeContent(language).carbonEmission.carbonContent
              .carbonDescription3
          }
        </p>

        <dl className="mt-10 space-y-10">
          <div className="relative">
            <dt>
              <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-arusgreen-500 text-white">
                <MapPinIcon className="h-6 w-6" aria-hidden="true" />
              </div>
              <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                {
                  localeContent(language).carbonEmission.carbonContent
                    .carbonHeader1
                }
              </p>
            </dt>
            <dd className="mt-2 ml-16 text-base text-gray-500 text-justify">
              {
                localeContent(language).carbonEmission.carbonContent
                  .carbonCaption1
              }
            </dd>
          </div>

          <div className="relative">
            <dt>
              <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-arusgreen-500 text-white">
                <GlobeAltIcon className="h-6 w-6" aria-hidden="true" />
              </div>
              <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                {
                  localeContent(language).carbonEmission.carbonContent
                    .carbonHeader2
                }
              </p>
            </dt>
            <dd className="mt-2 ml-16 text-base text-gray-500 text-justify">
              {
                localeContent(language).carbonEmission.carbonContent
                  .carbonCaption2
              }
            </dd>
          </div>
        </dl>
      </>
    )
  }

  render() {
    const language = Language.EN
    return (
      <div className="bg-white">
        <Whatsapp />
        <SEO
          title="Arus Oil - Reporting Carbon Emission Saved"
          description="Arus Oil provides environmental friendly features to track carbon emission saved for every used cooking oil you recycled and compliance to ISCC (International Sustainability & Carbon Certification) to ensure environment sustainability."
        />
        <Overlay
          loading={this.state.loading}
          text={localeContent(language).overlay.text}
        />
        <Header page={"Carbon Emissions"} />
        <Hero
          title={
            localeContent(language).carbonEmission.carbonTopTitle
              .carbonTitleBlack
          }
          content={
            localeContent(language).carbonEmission.carbonTopTitle
              .carbonDescription
          }
          image={MockUp}
          callToAction={
            <div className="mt-4 sm:mt-0">
              <Link to="/auth/signUp">
                <Button
                  className="w-full"
                  type="normal"
                  text={
                    localeContent(language).carbonEmission.carbonTopTitle
                      .carbonGetStarted
                  }
                />
              </Link>
            </div>
          }
          size={"small"}
          language={language}
        />
        <RightContentCard
          contain={true}
          title={
            localeContent(language).carbonEmission.carbonTopTitle.carbonTitle2
          }
          content={
            localeContent(language).carbonEmission.carbonTopTitle
              .carbonDescription2
          }
          image={CarbonISCC}
        />
        <LeftContentCard
          title={
            localeContent(language).carbonEmission.carbonContent.carbonTitle3
          }
          content={this.renderMethodology()}
          image={JMBTransaction}
          contain={true}
        />
        <RightContentCard
          image={AboutMPOB}
          contain={true}
          title={localeContent(language).mpobLicense.title}
          content={localeContent(language).mpobLicense.desc}
        />

        <SubscribeAction language={language} />
        <Footer />
      </div>
    )
  }
}
